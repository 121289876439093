import { Card, Form, Button, Switch } from "antd";
import { useState, useEffect } from "react";
import {
  FieldTimeOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Modal from "../../components/Modal";
import ComponentForm from "../../components/Form";
import { ALARM_ADD_FORM } from "../../data/forms";
import { convertTime } from "../../utils";

const AlarmCard = ({ timer, request, translate }) => {
  const [checked, setChecked] = useState(timer?.isenable === 1);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setLoading(true);
    request({
      url: "/alarm/edit",
      method: "POST",
      ismessage: true,
      body: {
        ...timer,
        isenable: e ? 1 : 0,
      },
    }).then((res) => {
      setLoading(false);
      if (res?.success) {
        setChecked(e);
      }
    });
  };
  const handleDelete = () => {
    setLoading(true);
    request({
      url: `/alarm/${timer.id}`,
      method: "DELETE",
      ismessage: true,
    }).then((res) => {
      setLoading(false);
      if (res?.success) {
        request({
          url: `/alarm/list`,
          model: "alarmlist",
        });
      }
    });
  };

  const orderDays = (days) => {
    let arr = days?.split(",");
    let sorted = arr?.sort();
    return sorted?.map(
      (item, i) => `${item}${sorted?.length - 1 === i ? "" : ","}`
    );
  };

  return (
    <Card hoverable actions={[<DeleteOutlined onClick={handleDelete} />]}>
      <div className="flex items-center justify-between border-b pb-2">
        <FieldTimeOutlined className="text-2xl" />
        <span className="font-semibold text-lg">{timer?.number}</span>
      </div>
      <div className="flex items-center justify-between pt-2">
        <div className="flex flex-col">
          <span className="font-semibold text-2xl">
            {orderDays(timer?.days)}{" "}
            <span className="text-xs">{translate("main.alarm3")}</span>
          </span>
          <span className="font-semibold text-2xl">
            {convertTime(timer?.hour, timer?.minute)}{" "}
            <span className="text-xs">{translate("main.alarm1")}</span>
          </span>
          <span className="font-semibold text-2xl">
            {timer?.startminute}{" "}
            <span className=" text-xs">{translate("main.alarm2")}</span>
          </span>
        </div>

        <Switch
          className="ml-3"
          checked={checked}
          onChange={handleChange}
          loading={loading}
        />
      </div>
    </Card>
  );
};

const Index = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    props.request({
      url: `/alarm/list`,
      model: "alarmlist",
    });
  }, []);
  const handleSave = (e) => {
    let days = e.days?.reduce((prev, curr) => {
      prev += `${curr},`;
      return prev;
    }, "");
    setIsLoading(true);
    let tmp = {
      id: e.id,
      carid: e.carid,
      isenable: e.isenable,
      hour: e.time.toString(),
      minute: e.minut.toString(),
      startminute: e.startminute,
      days: days.substring(0, days.length - 1),
      insby: props._auth?.user?.id,
    };
    props
      .request({
        url: `/alarm/edit`,
        method: "POST",
        body: tmp,
        ismessage: true,
      })
      .then((res) => {
        if (res.success) {
          form.resetFields();
          setIsOpen(false);
          props.request({
            url: `/alarm/list`,
            model: "alarmlist",
          });
        }
        setIsLoading(false);
      });
  };

  const onClose = () => {
    form.resetFields();
    setIsOpen(false);
  };
  const onOpen = () => {
    setIsOpen(true);
  };
  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 gap-5 mt-8">
        {props?.resalarmlist?.map((item, i) => (
          <AlarmCard
            key={i}
            timer={item}
            request={props?.request}
            translate={props?.translate}
          />
        ))}
        <Button
          type="dashed"
          onClick={onOpen}
          className="h-64 w-full flex items-center justify-center flex-col"
        >
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            {props?.translate("main.addalarm")}
          </div>
        </Button>
      </div>
      <Modal
        title={props?.translate("main.alarmregister")}
        open={isOpen}
        onClose={onClose}
        isShowFooter={false}
        width={600}
        height="70%"
      >
        <div className="p-5">
          <ComponentForm
            {...props}
            loading={loading}
            handleSave={handleSave}
            form={form}
            btnText={props?.translate("main.save")}
            data={ALARM_ADD_FORM}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Index;
