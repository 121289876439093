import React, { useState } from "react";
import { Button, Skeleton, Form } from "antd";
import ComponentForm from "../../Form";
import { useNavigate } from "react-router-dom";
import { Modal } from "../..";
import { TIMER_ADD_FORM } from "../../../data/forms";
import { licenseModal, convertTime } from "../../../utils";
const Index = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const getLoading = () => {
    return (
      <div className="flex flex-col w-full">
        <Skeleton.Button active className="mt-1 w-full" size="small" />
        <Skeleton.Button active className="mt-1 w-full" size="small" />
      </div>
    );
  };

  const getContent = () => {
    if (props?.time && props?.time.isenable) {
      return (
        <div className="flex flex-col w-full items-start">
          <span className="mt-2 font-semibold whitespace-normal text-sm md:text-xl">
            {convertTime(props?.time?.cyclehour, props?.time?.cycleminute)}
            {props?.translate("main.timer1")}
          </span>
          <span className="text-sm md:text-xl font-semibold whitespace-normal">
            {props?.time?.startminute} {props?.translate("main.alarm2")}
          </span>
        </div>
      );
    }
    return (
      <p className="whitespace-normal text-left mt-2">
        {props?.translate("main.notimer")}
      </p>
    );
  };

  const handleSetTimer = () => {
    props
      .request({ url: "/user/checklic", model: "licenseinfo" })
      .then((res) => {
        if (res.success) {
          if (props?.time) {
            form?.setFieldsValue({ ...props?.time });
          }
          setIsOpen(true);
        } else licenseModal(navigate, props?.translate);
      });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSave = (e) => {
    setIsLoading(true);
    let tmp = {
      id: e.id,
      carid: props?.data?.id,
      isenable: e.isenable,
      cyclehour: e.cyclehour,
      cycleminute: e.cycleminute,
      startminute: e.startminute,
      insby: props._auth?.user?.id,
    };
    props
      .request({
        url: `/timer/edit`,
        method: "POST",
        body: tmp,
        ismessage: true,
      })
      .then((res) => {
        if (res.success) {
          form.resetFields();
          setIsOpen(false);

          props.request({
            url: `/timer/list`,
            model: "timerlist",
          });
        }
        setIsLoading(false);
      });
  };
  return (
    <div className="w-full">
      <Button
        className={`h-full w-full rounded-xl p-5 flex flex-col border-light dark:border-border border`}
        onClick={handleSetTimer}
      >
        <div className="flex items-center">
          <img src={props?.icon} alt="icon" className={`w-6 icon`} />
          <span className="text-md ml-3 whitespace-normal text-left">
            {props?.text}
          </span>
        </div>
        {props?.loading === true ? getLoading() : getContent()}
      </Button>
      <Modal
        title={`${props?.data?.number} Цагийн асаалт бүртгэл`}
        open={isOpen}
        onClose={closeModal}
        isShowFooter={false}
        width={600}
        height="70%"
      >
        <ComponentForm
          {...props}
          loading={loading}
          handleSave={handleSave}
          form={form}
          btnText="Хадгалах"
          data={TIMER_ADD_FORM}
        />
      </Modal>
    </div>
  );
};

export default Index;
