import { Card, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { USER_FORM } from "../../data/forms";
import { Form, Cards } from "../../components";
const Index = (props) => {
  return (
    <div className="md:w-2/6 sm:w-full">
      <Card hoverable>
        <div className="flex items-center justify-center">
          <Avatar shape="square" size={64} icon={<UserOutlined />} />
        </div>
        <div className="mt-3">
          <Form
            initialValues={{ ...props?._auth?.user }}
            data={USER_FORM}
            hideBtn={true}
            {...props}
          />
          <Cards.ColorCard {...props} />
          <Cards.SetPinCard {...props} />
          <Cards.ChangePassCard {...props} />
        </div>
      </Card>
    </div>
  );
};

export default Index;
