const Index = (props) => {
  let infoclass = "";
  if (props?.type === 1 && props?.value > 0) {
    infoclass = "text-orange-500";
  } else if (props?.type === 1 && props?.value <= 0) {
    infoclass = "text-blue-500";
  } else if (props?.type === 2 && props?.value < 11.5) {
    infoclass = "text-red-500";
  } else if (props?.type === 2 && props?.value >= 11.5)
    infoclass = "text-green-500";
  else infoclass = "";

  const getColor = (index) => {
    if (props?.value === 0) return "bg-light_border";
    if (props?.value <= 5) {
      return index === 1 ? "bg-red-600" : "bg-light_border";
    } else if (props?.value <= 15) {
      return index <= 2 ? "bg-pink-600" : "bg-light_border";
    } else if (props?.value <= 23) {
      return index <= 3 ? "bg-yellow-600" : "bg-light_border";
    } else {
      return "bg-green-600";
    }
  };

  return (
    <div className="rounded-xl flex flex-row sm:flex-row md:flex-col justify-between items-end border-border_light dark:border-border border p-3">
      <div className="flex justify-start sm:justify-start md:justify-between w-full">
        <img src={props?.icon} className="w-6 sm:w-3 md:w-8 icon" />
        <span className="ml-2">{props?.text}</span>
      </div>
      {props?.type !== 3 ? (
        <span className={`text-xl mt-0 md:mt-2 w-20 text-right ${infoclass}`}>
          {props?.value}
        </span>
      ) : (
        <div className="flex items-end gap-0.5 relative justify-center">
          {props?.value === 0 ? (
            <div className="h-[2px] w-[30px] absolute top-[10px] rotate-45 bg-light_border rounded-lg" />
          ) : null}
          <div className={`h-[12px] w-[5px] rounded-md ${getColor(1)}`} />
          <div className={`h-[15px] w-[5px] rounded-md ${getColor(2)}`} />
          <div className={`h-[18px] w-[5px] rounded-md ${getColor(3)}`} />
          <div className={`h-[21px] w-[5px] rounded-md ${getColor(4)}`} />
        </div>
      )}
    </div>
  );
};

export default Index;
