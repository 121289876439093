import { memo, useEffect, useRef } from "react";
import iCloudy from "../../assets/weather/cloudy.svg";
import iSunny from "../../assets/weather/sunny.svg";
import dayjs from "dayjs";
import { Skeleton } from "antd";

const Index = ({ data, loading, translate }) => {
  const containerRef = useRef();
  const getImage = (wmo) => {
    if (wmo === 0) {
      return iSunny;
    } else if (wmo === 1) {
      return iCloudy;
    } else if (wmo === 3) {
      return iCloudy;
    }
    return iSunny;
  };

  useEffect(() => {
    if (data && data?.temperature_2m && data?.temperature_2m?.length) {
      const element = document.querySelector(
        `.weather-${dayjs()?.format("HH00")}`
      );
      if (element) {
        const relativeLeft =
          window.scrollX > containerRef.current.offsetLeft
            ? window.scrollX
            : containerRef.current.offsetLeft;
        containerRef.current?.scrollTo({
          left: element.offsetLeft - relativeLeft,
          behavior: "smooth",
        });
      }
    }
  }, [data]);

  return (
    <div
      className="flex overflow-x-auto space-x-2 py-2 mt-4"
      ref={containerRef}
    >
      {!data && loading === false ? (
        <span>{translate("main.nocarweather")}</span>
      ) : null}
      {data?.temperature_2m?.map((item, i) => (
        <div
          key={i}
          className={
            data?.time &&
            data?.time[i] &&
            `weather-${dayjs(data?.time[i])?.format("HHmm")}`
          }
          style={{ width: "7rem", maxWidth: "7rem", minWidth: "7rem" }}
        >
          <div className="bg-white dark:bg-black rounded-xl p-3 flex flex-col justify-between items-center border border-light_border dark:border-border w-full">
            {loading ? (
              <div className="flex flex-col items-center justify-center">
                <Skeleton.Avatar active className="h-fit" />
                <Skeleton.Button active className="w-full mt-2" size="small" />
                <Skeleton.Button active className="w-full mt-1" size="small" />
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <img
                  src={getImage(data?.weathercode[i])}
                  alt={item}
                  className="w-10"
                />
                <span className="text-sm">
                  {dayjs(data?.time[i])?.format("HH:mm")}
                </span>
                <span className="font-semibold text-xl mt-4">{`${item} °C`}</span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Index;
