import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Cards } from "../../components";
import { Button, QRCode, Statistic, Modal } from "antd";
import { QPAY_INVOICE, TERMINAL_ID, BANKS } from "../../data";
import dayjs from "dayjs";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { Countdown } = Statistic;
const { confirm } = Modal;

const Index = (props) => {
  const [active, setActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 480);
    return deadline;
  };
  useEffect(() => {
    props.request({ url: "/license/master", model: "licences" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let fake = 0;
  let changed = 0;

  const onChangeTimer = (val) => {
    if (fake !== dayjs(val).second()) {
      if (changed === 5) {
        if (invoice) {
          props
            .request({
              url: `/qpay/check`,
              method: "POST",
              body: {
                check: {
                  invoice_id: invoice?.id,
                  terminal_id: TERMINAL_ID,
                },
              },
              type: "payment",
            })
            .then((res) => {
              if (res.success) {
                if (res.data && res.data.invoice_status === "PAID")
                  setInvoice(null);
              }
            });
        }
        changed = 0;
      } else changed += 1;
    }
    fake = dayjs(val).second();
  };

  const onFinishTimer = () => {
    setInvoice(null);
  };

  const handleBuy = (item) => {
    setLoading(true);
    let tmp = QPAY_INVOICE;
    tmp.invoice.amount = active?.price;
    tmp.invoice.description = props?._auth?.user?.username;
    props
      .request({
        url: `/qpay/create`,
        method: "POST",
        body: tmp,
        type: "payment",
        ismessage: true,
      })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setInvoice(res?.data);
          setActive(item);
        }
      });
  };

  const changeActive = (item) => {
    if (item) {
      let text = "";
      if (invoice)
        text = `${props?.translate("main.youcancel")} ${
          item?.name
        } ${new Intl.NumberFormat().format(
          item?.price || 0
        )} ${props?.translate("main.create")}`;
      else
        text = `${props?.translate("main.youcreate")} ${
          item?.name
        } ${new Intl.NumberFormat().format(
          item?.price || 0
        )} ${props?.translate("main.create")}`;

      confirm({
        title: props?.translate("main.warning"),
        icon: <ExclamationCircleFilled />,
        content: text,
        maskClosable: true,
        centered: true,
        cancelText: props?.translate("main.close"),
        okText: props?.translate("main.yes"),
        onOk() {
          handleBuy(item);
        },
      });
    }
  };

  const handleCancel = () => {
    confirm({
      title: props?.translate("main.warning"),
      icon: <ExclamationCircleFilled />,
      content: "Үүсгэсэн нэхэмжлэхээ цуцлах гэж байна цуцлах уу ?",
      maskClosable: true,
      centered: true,
      cancelText: props?.translate("main.close"),
      okText: props?.translate("main.yes"),
      onOk() {
        setInvoice(null);
        setActive(null);
      },
    });
  };

  const handleDeeplink = (bank) => {
    if (invoice) {
      window.location.href = `${bank.deeplink}${invoice?.qr_code}`;
    }
  };

  return (
    <div className="w-full h-full">
      <span className="font-semibold text-xl">
        {props?.translate("main.license")}
      </span>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 2xl:grid-cols-7 py-2 gap-3 border-b border-light_grey dark:border-border">
        {props?.reslicences?.map((item, i) => (
          <Cards.LicenseCard
            key={i}
            data={item}
            setActive={changeActive}
            active={active}
            loading={loading}
            {...props}
          />
        ))}
      </div>
      <div className="text-md mt-5">
        {props?.translate("main.you")}{" "}
        <span className="text-active">
          {active?.name}- {new Intl.NumberFormat().format(active?.price || 0)}₮{" "}
        </span>
        {props?.translate("main.selectedlic")}
      </div>

      {invoice && (
        <div>
          <div className="md:w-1/3 sm:w-full mt-5 text-center items-center justify-center flex flex-col">
            <Countdown
              value={getDeadTime()}
              onChange={onChangeTimer}
              onFinish={onFinishTimer}
            />

            <QRCode value={invoice?.qr_code} size={180} className="mt-2" />
            <Button className="mt-2" type="dashed" onClick={handleCancel}>
              {props?.translate("main.cancel")}
            </Button>
            <span className="block mt-10 text-left">
              {props?.translate("main.qpay")}
              <br />
              <div className="flex items-center">
                <span className="w-28 block">
                  {" "}
                  {props?.translate("main.bank")}
                </span>
                <span className="text-active">
                  {" "}
                  {props?.translate("main.bankname")}
                </span>
              </div>
              <div className="flex items-center">
                <span className="w-28 block">
                  {" "}
                  {props?.translate("main.account")}
                </span>
                <span className="text-active">5628349442</span>
              </div>
              <div className="flex items-center">
                <span className="w-28 block">
                  {" "}
                  {props?.translate("main.amount")}
                </span>
                <span className="text-active">
                  {new Intl.NumberFormat().format(active?.price || 0)}₮
                </span>
              </div>
              <div className="flex items-center">
                <span className="w-28 block">
                  {" "}
                  {props?.translate("main.desc")}
                </span>
                <span className="text-active">{props?._auth?.user?.phone}</span>
              </div>
            </span>
          </div>
          {/* {isMobile ? (
            <div className="grid grid-cols-2 gap-3 mt-5">
              {BANKS.map((item, i) => (
                <div
                  key={i}
                  className="flex items-center border border-light_card rounded-md p-1"
                  onClick={() => handleDeeplink(item)}
                >
                  <img src={item.icon} className="w-10 h-10" />
                  <span className="ml-2">{item.name}</span>
                </div>
              ))}
            </div>
          ) : null} */}
        </div>
      )}
    </div>
  );
};

export default Index;
