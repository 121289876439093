import { Table } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Index = (props) => {
  const [selected, setSelected] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (props.editRef) props.editRef.current = selected;
  }, [selected]);

  useEffect(() => {
    setSelected(null);
  }, [location?.pathname]);

  useEffect(() => {
    if (!props?.open && selected) setSelected(null);
  }, [props?.open]);
  return (
    <Table
      size="small"
      scroll={{ x: "auto" }}
      className="mt-2"
      loading={props[`is${props?.config?.model}`]}
      rowClassName={(record) => {
        if (record?.statementid) {
          if (selected && record?.statementid === selected?.statementid) {
            return `bg-activeopa  transition-all`;
          }
        } else if (selected && record?.id === selected?.id) {
          return `bg-activeopa  transition-all`;
        }

        return "";
      }}
      dataSource={
        props?.dataSource
          ? props?.dataSource
          : props[`res${props?.config?.model}`] || []
      }
      columns={props?.columns ? props?.columns : props?.config?.columns}
      rowKey={(row) => row.id}
      onRow={(record) => {
        return {
          onDoubleClick: () => {
            props?.config?.doubleClick &&
              props?.search &&
              props?.search(record);
          },
          onClick: () => {
            setSelected(record);
          },
        };
      }}
    />
  );
};

export default Index;
