import Time from "./Time";
import { Button, Drawer, Modal } from "antd";
import { isMobile } from "react-device-detect";
import iMenu from "../../assets/icons/iMenu.png";
import logo from "../../assets/logos/logo.png";
import iNotification from "../../assets/icons/iNotification.png";
import { ThemeSwitch, SiderDrawer } from "..";
import Btn from "../ThemeSwitch/Btn";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Cards } from "..";
import { useEffect } from "react";
const { info } = Modal;
const Index = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNotif, setIsOpenNotif] = useState(false);
  const navigate = useNavigate();
  const getNotifCount = () =>
    props?.resnotifications?.filter((x) => x?.isview === 0)?.length || 0;

  const onClose = () => setIsOpen(false);
  const onCloseNotif = () => setIsOpenNotif(false);
  const handleOpen = () => setIsOpen(true);
  const navigateNotification = () => {
    //navigate("/notification");
    setIsOpenNotif(true);
  };

  useEffect(() => {
    if (isOpenNotif) {
      props?.request({ url: "/notification/user", model: "notifications" });
    }
  }, [isOpenNotif]);

  const onClickItem = (data) => {
    info({
      title: data?.header,
      content: (
        <div>
          <p>{data?.note}</p>
        </div>
      ),
      maskClosable: true,
      centered: true,
      okText: props?.translate("main.close"),
      onOk() {},
    });
    if (data?.isview === 0) {
      props
        .request({
          url: `/notification/user/detail/${data?.id}`,
        })
        .then((res) => {
          if (res.success) {
            data.isview = 1;
            props.request({
              url: "/notification/user",
              model: "notifications",
            });
          }
        });
    }
  };
  return (
    <div className="flex items-center justify-between border-b border-border_light dark:border-border pb-2 sm:pb-2 md:pb-5">
      <div className="flex items-center">
        <Button
          type="ghost"
          className="rounded-full block md:hidden mr-2"
          onClick={handleOpen}
          icon={<img src={iMenu} alt="iPic" className="w-9 icon" />}
        />
        <Time {...props} />
      </div>
      <img src={logo} alt="logo" className="w-72 hidden md:block" />
      <div className="flex items-center gap-3">
        <ThemeSwitch {...props} />
        <div className="border rounded-full p-1 relative">
          <Btn
            active={false}
            value=""
            icon={iNotification}
            onClick={navigateNotification}
          />
          {getNotifCount() ? (
            <span className="absolute text-black top-0 right-0 font-semibold bg-white rounded-full w-3 h-3 flex items-center justify-center">
              {getNotifCount()}
            </span>
          ) : null}
        </div>
      </div>
      <SiderDrawer {...props} isOpen={isOpen} onClose={onClose} />
      <Drawer
        title={props?.translate("main.notification")}
        placement="right"
        width={isMobile ? "80%" : 500}
        onClose={onCloseNotif}
        open={isOpenNotif}
      >
        <div className="flex flex-col py-2 px-5 w-full gap-2">
          {props?.resnotifications?.length ? (
            props?.resnotifications?.map((item, i) => (
              <Cards.NotificationCard
                key={i}
                data={item}
                onClick={onClickItem}
              />
            ))
          ) : (
            <div className="flex items-center justify-center py-10 ">
              <span className="text-border">
                {props?.translate("main.notifdesc")}
              </span>
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default Index;
