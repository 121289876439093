import { useContext, useEffect } from "react";
import { GlobalContext } from "../../context";
import { ConfigProvider, theme, Layout } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";
import { SocketProvider } from "../../socket";
import { URLS } from "../../utils";
import Main from "./main";
/*   ConfigProvider.config({
    theme: { token: { colorPrimary: "red" } },
  }); */
const Index = () => {
  const context = useContext(GlobalContext);
  const { defaultAlgorithm, darkAlgorithm } = theme;
  useEffect(() => {
    const prevtheme = localStorage.getItem("theme");
    if (prevtheme) {
      context?.setTheme(prevtheme);
    }

    if (window.ReactNativeData) {
      localStorage.setItem("deviceType", window?.ReactNativeData?.os);
      context?.setMobileData({ data: window.ReactNativeData });
    }
  }, []);

  return (
    <StyleProvider hashPriority="high">
      <ConfigProvider
        theme={{
          algorithm:
            context?.theme === "dark" ? darkAlgorithm : defaultAlgorithm,
          token: {
            colorPrimary: context?.color,
          },
        }}
      >
        <SocketProvider url={URLS.socket} isConnect={context?.islogin}>
          <Layout
            className={`main-window h-full w-full transition-all ${context?.theme}`}
          >
            <Main {...context} />
          </Layout>
        </SocketProvider>
      </ConfigProvider>
    </StyleProvider>
  );
};

export default Index;
