import { useState } from "react";
import { Form, Input, message, Switch } from "antd";
import { Popover, Button, Space, Popconfirm } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(false);

  const searchUser = (values) => {
    props
      ?.request({
        url: `/user/checkphone/${values?.phoneno}`,
        method: "POST",
        ismessage: true,
      })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setInfo(res?.data);
        }
      });
  };
  const handleSave = () => {
    if (info) {
      setLoading(true);
      props
        ?.request({
          url: `/user/addowncaruser/${props?.selected?.id}/${info?.id}/${
            checked ? 1 : 0
          }`,
          method: "POST",
          ismessage: true,
        })
        .then((res) => {
          setLoading(false);
          if (res.success) {
            form.resetFields();
            setInfo(null);
          }
        });
    } else {
      message.error("Хэрэлэгчийн мэдээлэл татагдаагүй байна.");
    }
  };

  return (
    <Popover
      trigger="click"
      placement="top"
      content={
        <div className="">
          <Form form={form} onFinish={searchUser}>
            {info ? (
              <div className="py-2 mb-2">
                <span className="text-sm text-success block">
                  Хэрэглэгч олдлоо: {info?.username}
                </span>
                <div className="flex items-center justify-between">
                  <span className="text-xs">GPS харуулах эсэх</span>
                  <Switch
                    size="small"
                    className="mt-2"
                    onChange={(e) => setChecked(e)}
                    value={checked}
                  />
                </div>
              </div>
            ) : null}
            <Form.Item
              name="phoneno"
              rules={[{ required: true, message: "Утасны дугаар оруулна уу" }]}
            >
              <Space.Compact
                style={{
                  width: "100%",
                }}
              >
                <Input placeholder="Утасны дугаар" />
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  icon={<SearchOutlined />}
                />
              </Space.Compact>
            </Form.Item>
            {info ? (
              <Popconfirm
                title={() => {
                  return (
                    <div className="flex flex-col">
                      <span>Хэрэглэгч нэмэхдээ итгэлтэй байна уу?</span>
                    </div>
                  );
                }}
                placement="topLeft"
                onConfirm={handleSave}
                okText="Тийм"
                cancelText="Үгүй"
              >
                <Button loading={loading} type="primary" className="w-full">
                  Нэмэх
                </Button>
              </Popconfirm>
            ) : null}
          </Form>
        </div>
      }
      title={props?.translate("main.addusercar")}
    >
      <Button
        // icon={<img src={iLocation} alt="iLocation" className="h-5 icon" />}
        icon={<UserOutlined />}
        className="flex items-center w-full font-semibold mt-2"
      >
        {props?.translate("main.addusercar")}
      </Button>
    </Popover>
  );
};

export default Index;
