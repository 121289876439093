import iHome from "../assets/icons/iHome.png";
import iSetting from "../assets/icons/iSetting.png";
import iBuy from "../assets/icons/iBuy.png";
import iShield from "../assets/icons/iShield.png";
import iProfile from "../assets/icons/iProfile.png";
import iSend from "../assets/icons/iSend.png";
import iScan from "../assets/icons/iScan.png";
import iTemp from "../assets/icons/iTemp.png";
import bank1 from "../assets/banks/1.png";
import bank2 from "../assets/banks/2.png";
import bank3 from "../assets/banks/3.png";
import bank4 from "../assets/banks/4.jpg";
import bank5 from "../assets/banks/5.png";
import bank6 from "../assets/banks/6.png";
import bank7 from "../assets/banks/7.jpg";
import bank8 from "../assets/banks/8.jpeg";
import bank9 from "../assets/banks/9.png";
import bank10 from "../assets/banks/10.png";
import bank11 from "../assets/banks/11.png";
import bank12 from "../assets/banks/12.png";
import bank13 from "../assets/banks/13.png";
import bank14 from "../assets/banks/14.png";
import bank15 from "../assets/banks/15.png";
import bank16 from "../assets/banks/16.png";

import { UserType } from "../enums";
import {
  USER_COLUMNS,
  CAR_COLUMNS,
  CAR_DETAIL_COLUMNS,
  USER_DETAIL_COLUMNS,
  BANNER_COLUMNS,
  CATEGORY_COLUMNS,
  LICENSE_LIST_COLUMNS,
  PAYMENT_HISTORY_COLUMNS,
  FEEDBACK_COLUMNS,
  NOTIF_COLUMNS,
} from "./columns";
import {
  ADD_BANNER_FORM,
  ADD_CAR_FORM,
  CATEGORY_ADD_FORM,
  EDIT_PERMISSION,
  NOTIF_ADD_FORM,
  ADD_LICENSE_FORM,
} from "./forms";

export const MENU = [
  {
    id: 1,
    route: "/",
    name: "main.homepage",
    icon: iHome,
    label: "Нүүр хуудас",
    isMenu: true,
  },
  {
    id: 2,
    route: "/shop",
    name: "main.shop",
    icon: iBuy,
    label: "Дэлгүүр",
    isMenu: true,
  },
  {
    id: 3,
    route: "/License",
    name: "main.license",
    icon: iScan,
    label: "Лиценз",
    isMenu: true,
  },
  {
    id: 8,
    route: "/list/feedback",
    icon: iSend,
    isMenu: true,
    role: [UserType.Хэрэглэгч],
  },
  {
    id: 4,
    route: "/",
    icon: iShield,
    isMenu: true,
    role: [UserType.Админ, UserType.Супер_админ],
    childs: [
      {
        key: 1,
        label: "sider.userlist",
        route: "/list/user",
        role: [UserType.Админ, UserType.Супер_админ],
        config: {
          search: "sdate,edate,phone,isenable",
          columns: USER_COLUMNS,
          url: "/admin/users",
          method: "POST",
          model: "users",
          doubleClick: {
            url: "/admin/usercars",
            columns: CAR_DETAIL_COLUMNS,
            title: "Холбоотой машинууд",
          },
          addform: {
            url: "/admin/useredit",
            title: "Хэрэглэгчийн эрх солих",
            method: "POST",
            data: EDIT_PERMISSION,
          },
        },
      },
      {
        key: 2,
        label: "sider.carlist",
        route: "/list/car",
        role: [UserType.Админ, UserType.Супер_админ],
        config: {
          search: "sdate,edate,carnumber,isenable",
          columns: CAR_COLUMNS,
          url: "/admin/carlist",
          method: "POST",
          model: "cars",
          doubleClick: {
            url: "/admin/carusers",
            columns: USER_DETAIL_COLUMNS,
            title: "Холбоотой хэрэглэгчид",
          },
          addform: {
            url: "/admin/addeditcar",
            nextUrl: [
              {
                url: "/admin/carlistpopup",
                method: "POST",
                model: "carspopup",
              },
            ],
            title: "main.carreg",
            method: "POST",
            data: ADD_CAR_FORM,
          },
        },
      },
      {
        key: 3,
        label: "sider.banner",
        route: "/list/banner",
        role: [UserType.Супер_админ],
        config: {
          search: "sdate,edate,isenable",
          columns: BANNER_COLUMNS,
          url: "/admin/getbanners",
          method: "POST",
          model: "adminbanners",
          addform: {
            url: "/admin/addbanner",
            title: "main.bannerreg",
            method: "POST",
            isfile: true,
            data: ADD_BANNER_FORM,
          },
        },
      },
      {
        key: 4,
        label: "sider.category",
        route: "/list/category",
        role: [UserType.Супер_админ],
        config: {
          search: "sdate,edate,isenable",
          columns: CATEGORY_COLUMNS,
          url: "/product/categorylist",
          method: "POST",
          model: "categorys",
          addform: {
            url: "/product/addcategory",
            method: "POST",
            title: "main.categoryreg",
            data: CATEGORY_ADD_FORM,
          },
        },
      },
      {
        key: 5,
        label: "sider.licenselist",
        route: "/list/licenseList",
        role: [UserType.Админ, UserType.Супер_админ],
        config: {
          search: "sdate,edate",
          columns: LICENSE_LIST_COLUMNS,
          url: "/license/adminlist",
          method: "POST",
          model: "licenselist",
          addform: {
            url: "/license",
            method: "POST",
            title: "main.categoryreg",
            data: ADD_LICENSE_FORM,
          },
        },
      },
      {
        key: 6,
        label: "sider.paymentlist",
        route: "/list/paymentlist",
        role: [UserType.Супер_админ],
        config: {
          search: "sdate,edate,userid,isactive,phoneno",
          columns: PAYMENT_HISTORY_COLUMNS,
          url: "/license/pays",
          method: "POST",
          model: "payments",
        },
      },
      {
        key: 7,
        label: "sider.notification",
        route: "/list/notificationList",
        role: [UserType.Супер_админ],
        config: {
          search: "sdate,edate,userid",
          columns: NOTIF_COLUMNS,
          url: "/notification/admin/list",
          method: "POST",
          model: "adminnotif",
          showCount: true,
          addform: {
            url: "/notification/admin/allsend",
            method: "POST",
            securl: "/notification/admin/send",
            secmethod: "POST",
            title: "main.sendnotif",
            data: NOTIF_ADD_FORM,
          },
        },
      },
      {
        key: 8,
        label: "sider.feedback",
        route: "/list/feedback",
        role: [UserType.Админ, UserType.Супер_админ, UserType.Хэрэглэгч],
        config: {
          search: "sdate,edate",
          columns: FEEDBACK_COLUMNS,
          url: "/feedback/list",
          method: "POST",
          model: "feedbacks",
        },
      },
      {
        key: 9,
        route: "/allmap",
        label: "sider.allmap",
        role: [UserType.Супер_админ],
      },
    ],
  },
  {
    id: 5,
    name: "main.settings",
    route: "/settings",
    icon: iProfile,
    isMenu: true,
    label: "Тохиргоо",
  },
  {
    id: 6,
    route: "/alarm",
  },
  {
    id: 7,
    route: "/notification",
  },
  {
    id: 8,
    name: "main.carsettings",
    route: "/carssettings",
    icon: iSetting,
    isMenu: true,
    label: "Машин тохиргоо",
  },
];

export const TERMINAL_ID = "f49b3f35-af6e-442f-b35e-52d42ea617e2";

export const QPAY_INVOICE = {
  invoice: {
    merchant_id: "4f3db212-40d1-43a6-b481-6e9cc2940f9d",
    customer_name: "",
    mcc_code: "5812",
    terminal_id: TERMINAL_ID,
    bank_accounts: [
      {
        is_default: true,
        account_bank_code: "050000",
        account_number: "5628349442",
        account_name: "Ти Эн Саплай",
      },
    ],
  },
};

export const BANKS = [
  {
    id: 1,
    name: "Khan Bank",
    icon: bank1,
    deeplink: "khanbank://q?qPay_QRcode=",
  },
  {
    id: 2,
    name: "TDB online",
    icon: bank2,
    deeplink: "tdbbank://q?qPay_QRcode=",
  },
  {
    id: 3,
    name: "Social Pay",
    icon: bank13,
    deeplink: "socialpay-payment://q?qPay_QRcode=",
  },
  {
    id: 4,
    name: "State Bank",
    icon: bank3,
    deeplink: "statebank://q?qPay_QRcode=",
  },
  {
    id: 5,
    name: "State Bank 3.0",
    icon: bank16,
    deeplink: "statebankmongolia://q?qPay_QRcode=",
  },
  {
    id: 6,
    name: "XacBank",
    icon: bank4,
    deeplink: "xacbank://q?qPay_QRcode=",
  },
  {
    id: 7,
    name: "Capitron Bank",
    icon: bank5,
    deeplink: "capitronbank://q?qPay_QRcode=",
  },
  {
    id: 8,
    name: "Chinggis Khaan",
    icon: bank6,
    deeplink: "ckbank://q?qPay_QRcode=",
  },
  {
    id: 9,
    name: "Bogd Bank",
    icon: bank7,
    deeplink: "bogdbank://q?qPay_QRcode=",
  },
  {
    id: 10,
    name: "NI Bank",
    icon: bank8,
    deeplink: "nibank://q?qPay_QRcode=",
  },
  {
    id: 11,
    name: "MostMoney",
    icon: bank9,
    deeplink: "most://q?qPay_QRcode=",
  },
  {
    id: 12,
    name: "TransBank",
    icon: bank10,
    deeplink: "transbank://q?qPay_QRcode=",
  },
  {
    id: 13,
    name: "M Bank",
    icon: bank11,
    deeplink: "mbank://q?qPay_QRcode=",
  },
  {
    id: 14,
    name: "Monpay",
    icon: bank14,
    deeplink: "Monpay://q?qPay_QRcode=",
  },
  {
    id: 15,
    name: "Ard App",
    icon: bank12,
    deeplink: "ard://q?qPay_QRcode=",
  },
  {
    id: 16,
    name: "QPAY Wallet",
    icon: bank15,
    deeplink: "qpaywallet://q?qPay_QRcode=",
  },
];

export const COLORS = [
  "#F5222D",
  "#FA8C16",
  "#FADB14",
  "#8BBB11",
  "#52C41A",
  "#13A8A8",
  "#1677FF",
  "#2F54EB",
  "#722ED1",
  // "#EB2F96",
  "#00b96b",
];
