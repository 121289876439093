import OtpInput from "react-otp-input";
const Index = (props) => {
  const getSize = () => {
    if (props?.mobiledata) return "5rem";
    if (props?.size === "large") return "3rem";
    return "2rem";
  };

  const getSep = () => {
    if (props?.size === "large")
      return <span className="text-2xl px-2">-</span>;
    return <span>-</span>;
  };

  const size = getSize();

  return (
    <div className="flex justify-center otp-container">
      <OtpInput
        inputType="tel"
        containerStyle={{}}
        inputStyle={{
          fontSize: props?.mobiledata ? "3rem" : "1.5rem",
          border: "1px solid #e5e7eb",
          width: size,
          height: size,
          outline: "none",
          borderRadius: "0.5rem",
        }}
        value={props?.otp}
        onChange={props?.setOtp}
        numInputs={4}
        renderSeparator={getSep()}
        renderInput={(props, index) => {
          return <input {...props} className="text-black" />;
        }}
      />
    </div>
  );
};

export default Index;
