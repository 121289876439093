import { memo } from "react";
import { Button, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { licenseModal, convertTime } from "../../../utils";
const Index = (props) => {
  const navigate = useNavigate();
  const getLoading = () => {
    return (
      <div className="flex flex-col w-full">
        <Skeleton.Button active className="mt-1 w-full" size="small" />
        <Skeleton.Button active className="mt-1 w-full" size="small" />
      </div>
    );
  };

  const getContent = () => {
    if (props?.time && props?.time?.isenable) {
      return (
        <div className="flex flex-col w-full items-start">
          <span className="mt-2 text-sm md:text-xl font-semibold whitespace-normal">
            {convertTime(props?.time?.hour, props?.time?.minute)}
            {props?.translate("main.alarm1")}
          </span>
          <span className="text-sm md:text-xl font-semibold whitespace-normal">
            {props?.time?.startminute} {props?.translate("main.alarm2")}
          </span>
        </div>
      );
    }
    return (
      <p className="whitespace-normal text-left mt-2">
        {props?.translate("main.noalarm")}
      </p>
    );
  };
  const handleSetAlarm = () => {
    props
      .request({ url: "/user/checklic", model: "licenseinfo" })
      .then((res) => {
        if (res.success) {
          navigate("/alarm");
        } else licenseModal(navigate, props?.translate);
      });
  };

  return (
    <Button
      className={`h-full rounded-xl p-5 flex flex-col border-light dark:border-border border`}
      onClick={handleSetAlarm}
    >
      <div className="flex items-center">
        <img src={props?.icon} alt="icon" className={`w-6 icon`} />
        <span className="text-md ml-3">{props?.text}</span>
      </div>
      {props?.loading === true ? getLoading() : getContent()}
    </Button>
  );
};

export default Index;
