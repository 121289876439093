import { useState, useEffect } from "react";
import { SET_ADMIN_NUMBER } from "../../../data/forms";
import { Form as AntdForm, Input, message } from "antd";
import { Popover, Button } from "antd";
import { Form } from "../..";
import { UserOutlined } from "@ant-design/icons";
const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const [adminNumbers, setAdminNumbers] = useState(null);

  const sendCommand = (cmd, imei, number) => {
    let body = {
      userid: props?._auth?.user?.id,
      imei: imei,
      cmd: cmd,
      type: 0,
      number: number,
    };

    props?.request({
      url: "/action",
      type: "socket",
      method: "POST",
      body: body,
    });
  };

  useEffect(() => {
    if (props?.selected) {
      setAdminNumbers({
        adminnumber1: props?.selected?.adminnumber || "",
        adminnumber2: props?.selected?.adminnumbersecond || "",
        adminnumber3: props?.selected?.adminnumberthird || "",
      });
    }
  }, [props?.selected]);

  const changeNumber = (value, attr) => {
    setAdminNumbers({ ...adminNumbers, [attr]: value?.target?.value });
  };

  const handleSave = (attr, order) => {
    setLoading(true);
    props?.request({ url: `/sock/${props?.selected?.imei}` })?.then((res) => {
      if (res?.success) {
        sendCommand(
          12,
          props?.selected?.imei,
          `${order - 1},${adminNumbers[attr]}`
        );
        props
          ?.request({
            url: `/user/adminnumber`,
            method: "POST",
            ismessage: true,
            body: {
              order: order,
              carid: props?.selected?.id,
              number: adminNumbers[attr],
            },
          })
          .then((res) => {
            props.request({
              url: `/user/cars`,
              model: "carsinfo",
              method: "POST",
            });
            setLoading(false);
          });
      } else {
        setLoading(false);
        message.error("Тухайн машин холбогдоогүй байна.");
      }
    });
  };

  return (
    <Popover
      trigger="click"
      content={
        <div className="flex flex-col gap-1.5">
          <div className="flex gap-1">
            <Input
              placeholder="Админ дугаар 1"
              size="small"
              value={adminNumbers?.adminnumber1}
              onChange={(e) => changeNumber(e, "adminnumber1")}
            />
            <Button
              type="primary"
              size="small"
              loading={loading}
              onClick={() => handleSave("adminnumber1", 1)}
            >
              Хадгалах
            </Button>
          </div>
          <div className="flex gap-1">
            <Input
              placeholder="Админ дугаар 2"
              size="small"
              value={adminNumbers?.adminnumber2}
              onChange={(e) => changeNumber(e, "adminnumber2")}
            />
            <Button
              type="primary"
              size="small"
              loading={loading}
              onClick={() => handleSave("adminnumber2", 2)}
            >
              Хадгалах
            </Button>
          </div>
          <div className="flex gap-1">
            <Input
              placeholder="Админ дугаар 3"
              size="small"
              value={adminNumbers?.adminnumber3}
              onChange={(e) => changeNumber(e, "adminnumber3")}
            />
            <Button
              type="primary"
              size="small"
              loading={loading}
              onClick={() => handleSave("adminnumber3", 3)}
            >
              Хадгалах
            </Button>
          </div>
        </div>
      }
      title={props?.translate("main.setadmin")}
    >
      <Button
        // icon={<img src={iLocation} alt="iLocation" className="h-5 icon" />}
        icon={<UserOutlined />}
        /*  type="link" */
        className="flex items-center w-full font-semibold  mt-2"
      >
        {props?.translate("main.setadmin")}
      </Button>
    </Popover>
  );
};

export default Index;
