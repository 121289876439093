import React, { useState } from "react";
import { QRCodeScanner } from "../../components";
import { useNavigate } from "react-router-dom";

const App = () => {
  const [qrCodeText, setQrCodeText] = useState("");
  const navigate = useNavigate();
  const handleScanSuccess = (decodedText) => {
    /*  navigate(-1, {
      state: {
        imei: decodedText,
      },
    }); */
  };

  const handleScanFailure = (error) => {
    console.error(`QR Code scan failed: ${error}`);
  };

  return (
    <div className=" h-full w-full">
      <span className="block text-center w-full py-5 text-md">
        Та төхөөрөмж дээр байрлах QR кодыг уншуулна уу.
      </span>
      <QRCodeScanner
        onScanSuccess={handleScanSuccess}
        onScanFailure={handleScanFailure}
      />
    </div>
  );
};

export default App;
