import { Form } from "..";
import { Button } from "antd";
import { ISENABLE, SUCCESSORFAIL } from "../../data/forms";
import { useEffect } from "react";
const Index = (props) => {
  const handleSearch = (values) => {
    if (props?.config?.url && props?.config?.method && props?.config?.model) {
      props.request({
        url: props?.config?.url,
        model: props?.config?.model,
        method: props?.config?.method,
        body: values,
      });
    }
  };

  useEffect(() => {
    if (props?.resreload === true) {
      props?.form?.submit();
    }
  }, [props?.resreload]);

  const getControl = (name) => {
    switch (name) {
      case "phone":
        return {
          type: "input",
          placeholder: props?.translate("control.phoneno"),
          name: name,
        };
      case "carnumber":
        return {
          type: "input",
          placeholder: props?.translate("control.carnumber"),
          name: name,
        };
      case "sdate":
        return {
          type: "date",
          placeholder: props?.translate("control.sdate"),
          name: name,
        };
      case "edate":
        return {
          type: "date",
          placeholder: props?.translate("control.edate"),
          name: name,
        };
      case "isenable":
        return {
          type: "select",
          placeholder: props?.translate("control.status"),
          options: ISENABLE,
          name: name,
        };
      case "isactive":
        return {
          type: "select",
          placeholder: props?.translate("control.status"),
          options: SUCCESSORFAIL,
          name: name,
        };
      case "userid":
        return {
          type: "select",
          placeholder: props?.translate("control.user"),
          options: "userspopup",
          fieldNames: {
            label: "username",
            value: "id",
          },
          name: name,
        };
      default:
        return {
          type: "input",
          placeholder: props?.translate("control.phoneno"),
          name: name,
        };
    }
  };

  const getSearchControl = () => {
    if (props?.config?.search) {
      const searchControls = props?.config?.search.split(",");
      if (searchControls && searchControls.length) {
        let tmp = searchControls.map((item) => getControl(item));
        return tmp;
      }
    }
    return [];
  };

  const handleNew = () => {
    if (props?.handleNew && props?.config?.addform) props?.handleNew();
  };

  const handleEdit = () => {
    if (props?.handleEdit && props?.config?.addform) props?.handleEdit();
  };

  return (
    <div className="flex w-full">
      <Form
        className="w-full"
        // layout="inline"
        name="search"
        form={props?.form}
        btnTitle={props?.translate("main.search")}
        data={getSearchControl()}
        handleSave={handleSearch}
        loading={props?.config?.loading === true}
        handleNew={handleNew}
        handleEdit={handleEdit}
        isAddForm={props?.config?.addform}
        {...props}
      />
    </div>
  );
};

export default Index;
