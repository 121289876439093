import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import { App } from "./containers";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { GlobalProvider } from "./context";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import "dayjs/locale/mn";
import L from "leaflet";
import { ClearCacheProvider } from "react-clear-cache";
import iCar from "./assets/icons/iCar.png";
let DefaultIcon = L.icon({
  iconUrl: iCar,
});

L.Marker.prototype.options.icon = DefaultIcon;
dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ClearCacheProvider duration={10000} auto={true}>
    <BrowserRouter>
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </BrowserRouter>
  </ClearCacheProvider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
