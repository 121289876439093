import { Modal, Drawer, Button } from "antd";
import { isMobile } from "react-device-detect";

const Index = (props) => {
  const handleClose = () => {
    props?.onClose && props?.onClose();
  };
  const handleSubmit = () => {
    props?.handleSubmit && props.handleSubmit();
  };

  const Footer = ({ isModal }) => {
    return (
      <div
        className={`flex w-full gap-3 justify-end items-center mt-5 ${
          !isModal && "absolute bottom-0"
        }`}
      >
        <Button onClick={handleClose}>{props?.translate("main.close")}</Button>
        <Button type="primary" onClick={handleSubmit}>
          {props?.translate("main.save")}
        </Button>
      </div>
    );
  };

  const getDrawerStyle = () => {
    const style = {
      position: "absolute",
      borderTopLeftRadius: "2rem",
      borderTopRightRadius: "2rem",
    };
    if (props?.open) {
      style.transform = undefined;
    }
    return style;
  };

  if (isMobile) {
    return (
      <Drawer
        title={props?.title ? props?.title : null}
        placement="bottom"
        onClose={handleClose}
        closable={false}
        open={props?.open}
        height={props?.height}
        style={getDrawerStyle()}
      >
        <div className="flex flex-col h-full relative px-5 pt-2">
          {props?.children}
          {props?.isShowFooter && <Footer />}
        </div>
      </Drawer>
    );
  }
  return (
    <Modal
      title={props?.title ? props?.title : null}
      width={props?.width ? props?.width : null}
      centered
      open={props?.open}
      onClose={handleClose}
      onOk={handleSubmit}
      onCancel={handleClose}
      footer={null}
    >
      <div className="relative flex flex-col h-full">
        {props?.children}
        {props?.isShowFooter && <Footer className="mt-20" isModal={true} />}
      </div>
    </Modal>
  );
};

export default Index;
