import { useState } from "react";
import { Popover, Button, message, Form as AntdForm } from "antd";
import { Form, Otp } from "../..";
import { SET_PIN_FORM } from "../../../data/forms";
const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = AntdForm.useForm();
  const [pin, setPin] = useState("");

  const savePin = (values) => {
    if (!pin || pin?.length !== 4) {
      message.error(props?.translate("main.enterpin"));
      return;
    }
    setLoading(true);
    props
      ?.request({
        url: "/user/changepin",
        method: "POST",
        ismessage: true,
        body: {
          pin: pin,
          pass: values?.password,
          ismessage: true,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          props.request({ url: "/user/ourdata", model: "userdata" });
          setPin("");
          form.resetFields();
        }
      });
  };
  const deletePin = () => {
    form.validateFields();
    let values = form?.getFieldsValue();
    if (values.password) {
      props
        ?.request({
          url: "/user/changepin",
          method: "POST",
          ismessage: true,
          body: {
            pass: values?.password,
          },
        })
        .then((res) => {
          setLoading(false);
          if (res.success) {
            props.request({ url: "/user/ourdata", model: "userdata" });
            setPin("");
            form.resetFields();
          }
        });
    }
  };
  return (
    <Popover
      trigger="click"
      content={
        <div className="">
          <Otp otp={pin} setOtp={setPin} size="large" />
          <Form
            {...props}
            form={form}
            name="pin-form"
            loading={loading}
            handleSave={savePin}
            className="mt-2"
            data={SET_PIN_FORM}
          />
          {props?.resuserdata?.ispin && (
            <Button className="w-full mt-2" onClick={deletePin}>
              Устгах
            </Button>
          )}
        </div>
      }
      title={props?.translate("main.setpintitle")}
    >
      <Button className="w-full rounded-md p-2 mt-2 flex items-center border justify-between">
        <span className="font-semibold">{props?.translate("main.setpin")}</span>
        <span
          className={`text-xs ${
            props?.resuserdata?.ispin ? "text-success" : "text-danger"
          }`}
        >
          {props?.resuserdata?.ispin
            ? props?.translate("main.setted")
            : props?.translate("main.notset")}
        </span>
      </Button>
    </Popover>
  );
};

export default Index;
