import React from "react";
import { message, Button } from "antd";
import faceId from "../../assets/icons/iFace.png";
import fingerprint from "../../assets/icons/iFinger.png";
import { postMessageMobile, useMobileEvent } from "../../utils";
import { useNavigate } from "react-router-dom";

function Index(props) {
  const navigate = useNavigate();
  const handleSkip = () => {
    postMessageMobile({ type: "DELETE_CREDENTIAL", data: "" });
    navigate("/");
  };

  const handleMobileRequest = (e) => {
    try {
      let data = JSON.parse(e.data);
      if (data) {
        if (data.type === "CREDENTIAL_SUCCESS") {
          navigate("/");
        } else if (data.type === "CREDENTIAL_ERROR") {
          message.error(data.msg);
        }
      }
    } catch (e) {}
  };

  useMobileEvent(handleMobileRequest);

  const handleApprove = () => {
    let biometric = localStorage.getItem("biometric");
    if (biometric) biometric = JSON.parse(biometric);
    if (biometric && biometric?.name && biometric?.pass) {
      postMessageMobile({
        type: "SAVE_CREDENTIAL",
        data: { name: biometric?.name, pass: biometric?.pass },
      });
    } else {
      message.error("Алгасах дарж үргэлжилүүлнэ үү");
    }
  };

  const generateIcon = () => {
    if (
      props?.mobiledata?.bioType === "FINGER" ||
      props?.mobiledata?.bioType === "TOUCHID"
    ) {
      return fingerprint;
    } else if (props?.mobiledata?.bioType === "FACEID") {
      return faceId;
    }
    return fingerprint;
  };

  const generateText = () => {
    if (props?.mobiledata?.bioType === "FINGER") {
      return "FINGERPRINT";
    } else if (props?.mobiledata?.bioType === "FACEID") {
      return "FACE ID";
    } else if (props?.mobiledata?.bioType === "TOUCHID") {
      return "TOUCH ID";
    }
    return "FINGERPRINT";
  };
  return (
    <div className="bg-white w-full h-full px-12 flex flex-col items-center justify-center">
      <img src={generateIcon()} alt="faceid" className="h-24 pb-8" />
      <span
        className="uppercase font-bold text-center pb-4"
        style={{ fontSize: "0.8rem" }}
      >
        Та цаашид нэвтрэхдээ{" "}
        <span className="text-primary">{generateText()}</span> ашиглах уу?
      </span>
      <span className="text-customGrey40 text-center pb-16">
        Та <span className="text-primary uppercase">{generateText()}</span> -ийг
        тохиргоог идэвхжүүлснээр цаашид апп руу нэвтрэхэд нэвтрэх нэр нууц үг
        хийх шаардлагагүй.
      </span>
      <Button className="py-2  w-full mb-4" onClick={handleSkip}>
        Алгасах
      </Button>
      <Button className="py-2 w-full   " onClick={handleApprove}>
        Зөвшөөрөх
      </Button>
    </div>
  );
}
export default Index;
