import { Button, Drawer } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import iLogOut from "../../assets/icons/iLogout.png";
import { ThemeSwitch, LangSwitch } from "..";
import { MENU } from "../../data";
import { UserType } from "../../enums";

const Index = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("auth");
    props.setlogin(false);
    navigate("/login");
    props?.onClose();
  };

  const changeRoute = (item) => {
    if (item?.route) {
      navigate(item?.route);
      props?.onClose();
    }
  };

  const getMenus = () => {
    let tmp = [];
    if (props?._auth?.user?.type === UserType.Хэрэглэгч) {
      MENU?.map((item, i) => {
        if (item?.label) {
          tmp.push(
            <Button
              key={i}
              className="flex items-center"
              type={`${
                location?.pathname === item?.route ? "primary" : "default"
              }`}
              onClick={() => changeRoute(item)}
            >
              {props?.translate(item?.name)}
            </Button>
          );
        }
      });
    } else {
      MENU?.map((item) => {
        item?.childs?.map((child, i) => {
          tmp.push(
            <Button
              key={i}
              className="flex items-center"
              type={`${
                location?.pathname === child?.route ? "primary" : "default"
              }`}
              onClick={() => changeRoute(child)}
            >
              {props?.translate(child?.label)}
            </Button>
          );
        });
      });
    }

    return tmp;
  };

  return (
    <Drawer
      placement="left"
      width="70%"
      closable={true}
      onClose={props?.onClose}
      open={props?.isOpen}
      getContainer="main-window"
    >
      <div className="h-full flex flex-col justify-between p-3">
        <div className="flex flex-col gap-2">
          <div className="flex w-full items-center justify-between">
            <ThemeSwitch {...props} show={true} />
            <LangSwitch {...props} />
          </div>
          {getMenus()}
        </div>
        <Button
          onClick={handleLogout}
          className="flex items-center"
          size="large"
          icon={<img src={iLogOut} alt="iLogOut" className="icon h-6" />}
        >
          {props?.translate("main.logout")}
        </Button>
      </div>
    </Drawer>
  );
};

export default Index;
