import { Carousel } from "antd";
import { useEffect, useState } from "react";
import { IsEnable } from "../../enums";
import {
  SetAddUser,
  SetTimeCar,
  SetLocationCard,
  AddAdminNumber,
  MessageLog,
} from "../../components/Cards";

const Index = (props) => {
  const [selected, setSelected] = useState(null);
  const [carusers, setCarUsers] = useState([]);
  const [loclist, setLocList] = useState([]);

  useEffect(() => {
    if (!selected && props?.rescarsinfo && props?.rescarsinfo[0])
      setSelected(props.rescarsinfo[0]);
  }, [props?.rescarsinfo]);

  useEffect(() => {
    if (selected?.issuper === IsEnable.Идэвхтэй) {
      props
        ?.request({
          url: `/user/carownusers/${selected?.id}`,
          method: "POST",
        })
        .then((res) => {
          if (res?.success) {
            setCarUsers(res?.data);
          }
        });
      props
        ?.request({
          url: `/user/userlocation/list/${selected?.id}`,
          method: "POST",
        })
        .then((res) => {
          if (res?.success) {
            setLocList(res?.data);
          }
        });
    } else {
      setLocList([]);
      setCarUsers([]);
    }
  }, [selected]);

  const slideChange = (index) => {
    if (index >= 0 && props?.rescarsinfo?.length && props?.rescarsinfo[index])
      setSelected(props?.rescarsinfo[index]);
  };

  return (
    <div className="grid grid-cols-12 gap-2 sm:gap-2 md:gap-8 h-full overflow-y-auto">
      <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-5 2xl:col-span-4">
        <div className="border shadow-lg border-light_border dark:border-border rounded-lg bg-white dark:bg-card">
          <Carousel
            draggable
            swipeToSlide
            initialSlide={0}
            afterChange={slideChange}
          >
            {props?.rescarsinfo?.map((item, i) => (
              <div
                className="p-5 flex flex-col items-center justify-center cursor-pointer"
                key={i}
              >
                <span className="font-bold text-2xl">{item?.name}</span>
                <span>{item?.number}</span>
              </div>
            ))}
          </Carousel>
        </div>

        <div className="border shadow-lg border-light_border dark:border-border rounded-lg bg-white dark:bg-card mt-5 p-5">
          <SetTimeCar
            {...props}
            carusers={carusers}
            loclist={loclist}
            selected={selected}
          />
          {selected?.issuper === IsEnable.Идэвхтэй ? (
            <SetLocationCard
              {...props}
              carusers={carusers}
              loclist={loclist}
              selected={selected}
            />
          ) : null}

          <SetAddUser {...props} selected={selected} />
          <AddAdminNumber {...props} selected={selected} />
          <MessageLog {...props} selected={selected} />
        </div>
      </div>
    </div>
  );
};

export default Index;
