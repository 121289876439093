import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form as AntdForm } from "antd";
import { Form } from "../../../components";
import {
  CREATEPASSWORD_VALIDATOR,
  CONFIRMPASSWORD_VALIDATOR,
} from "../../../utils/validations";

const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = AntdForm.useForm();
  const data = JSON.parse(localStorage.getItem("register"));
  useEffect(() => {
    document.title = "Нууц үг сэргээх";
  }, []);

  const handleSave = (e) => {
    setLoading(true);
    if (data.type === "forgetpass") {
      props
        .request({
          url: `/user/resetpass`,
          method: "POST",
          ismessage: true,
          body: {
            phone: data?.phone,
            pass: e?.confirmpassword,
            code: data?.otp,
          },
        })
        .then((res) => {
          setLoading(false);
          if (res.success) {
            localStorage.removeItem("register");
            navigate("/login");
          }
        });
    } else {
      let tmp = {
        username: data?.phone,
        phone: data?.phone,
        password: e.confirmpassword,
        code: data?.otp,
      };
      props
        .request({
          url: `/user/signup`,
          method: "POST",
          body: tmp,
          ismessage: true,
        })
        .then((res) => {
          setLoading(false);
          if (res.success) {
            navigate("/login");
          }
        });
    }
  };

  const handleBack = () => navigate(-1);

  return (
    <div className="bg-white dark:bg-gray-900 h-full">
      <div className="flex justify-center h-full ">
        <div
          className="hidden bg-cover bg-no-repeat lg:block lg:w-3/6 "
          style={{
            backgroundImage:
              "url(https://www.mbusa.com/content/dam/mb-nafta/us/mobile-applications/mercedes_me_connect_finance_img.jpg)",
          }}
        >
          <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40 rounded-lg">
            <div>
              <h2 className="text-4xl font-bold text-white">Automotive</h2>

              <p className="max-w-xl mt-3 text-gray-300">
                Та өөрийн автомашинаа хаанаас ч удирдах боломжтой.
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
          <div className="flex-1">
            <div className="text-center">
              <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">
                Нууц үг сэргээх
              </h2>

              <p className="mt-3 text-gray-500 dark:text-gray-300">
                Та бүртгэлтэй утасны дугаараа оруулна уу.
              </p>
            </div>

            <div className="mt-8">
              <Form
                form={form}
                data={[
                  {
                    type: "inputpassword",
                    placeholder: "Нууц үг",
                    name: "password",
                    required: true,
                    size: "large",
                    rules: CREATEPASSWORD_VALIDATOR,
                  },
                  {
                    type: "inputpassword",
                    placeholder: "Нууц үг давтах",
                    name: "confirmpassword",
                    required: true,
                    size: "large",
                    rules: CONFIRMPASSWORD_VALIDATOR,
                  },
                ]}
                handleSave={handleSave}
                loading={loading}
                className="mt-2"
                size="large"
                btnTitle="main.next"
                {...props}
              />

              <div className="flex mt-4 justify-center">
                <div
                  onClick={handleBack}
                  className="cursor-pointer text-sm text-center text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline"
                >
                  Буцах
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
