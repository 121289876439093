export const IsEnable = {
  Идэвхтэй: 1,
  Идэвхгүй: 0,
};

export const CarType = { Sedan: 1, Cuv: 2, Suv: 3, Hatchback: 4, Van: 5 };

export const UserType = {
  Супер_админ: 1,
  Админ: 2,
  Хэрэглэгч: 3,
};
