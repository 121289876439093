import { Button, Tooltip, message, Drawer, Popover, Form, Select } from "antd";
import { LoadingOutlined, HistoryOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Modal as CustomModal, Otp } from "..";
import { useRef, useState } from "react";
import iLock from "../../assets/icons/iLock.png";
import iUnlock from "../../assets/icons/iUnlock.png";
import iOff from "../../assets/icons/iOff.png";
import iOn from "../../assets/icons/iOn.png";
import { licenseModal } from "../../utils";
import { isMobile } from "react-device-detect";
import useSound from "use-sound";
import startSound from "../../assets/sounds/startSound.mp3";
import offSound from "../../assets/sounds/offSound.mp3";
import lockSound from "../../assets/sounds/lockSound.mp3";
import dayjs from "dayjs";

const Btn = ({ icon, onClick, value, loading, text, className }) => {
  const [clicked, setClicked] = useState("NOT CLICKED");
  const timeoutRef = useRef(null);

  const handleMouseDown = () => {
    timeoutRef.current = setTimeout(() => {
      if (onClick && !loading) onClick(value);
    }, 1800);
  };

  const handleMouseUp = () => {
    clearTimeout(timeoutRef.current);
  };

  return (
    <div className="centrify bg-white dark:bg-antddark rounded-xl border">
      <button
        className="confBtn flex items-center justify-center w-full bg-white dark:bg-antddark rounded-xl"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
      >
        {loading ? (
          <LoadingOutlined />
        ) : (
          <>
            <div className="rounded-lg bg-light_card"></div>
            <img src={icon} alt={value} className={`z-10 w-10 ${className}`} />
          </>
        )}
      </button>
    </div>
  );

  /*   return (
    <Button
      className={`flex rounded-xl items-center justify-center py-10 w-full`}
      onClick={() => (onClick && !loading ? onClick(value) : undefined)}
      icon={
        loading ? (
          <LoadingOutlined />
        ) : (
          <img src={icon} alt={value} className={`w-10 ${className}`} />
        )
      }
    />
  ); */
};

const Index = (props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [history, setHistory] = useState([]);
  const [openHistory, setOpenHistory] = useState(false);
  const cmdRef = useRef();
  const navigate = useNavigate();
  const [start] = useSound(startSound);
  const [off] = useSound(offSound);
  const [lock] = useSound(lockSound);

  const callStartSound = () => {
    start();
    props?.request({
      url: `/user/car/powertime/start/${props?.data?.id}`,
      method: "POST",
    });
  };

  const onCloseHistory = () => {
    setOpenHistory(false);
  };

  const onOpenHistory = () => {
    setLoading(true);
    props
      ?.request({
        url: `/user/carlog/list/${props?.data?.id}`,
        method: "POST",
        ismessage: true,
      })
      .then((res) => {
        setLoading(false);
        if (res?.success) {
          setHistory(res?.data || []);
          setOpenHistory(true);
        }
      });
  };

  const handleControl = (cmd) => {
    if (props?.isDemo) {
      message.error(props?.translate("main.democontrol"));
    } else if (props?.reslicenseinfo === true) {
      if (props?.resuserdata?.ispin) {
        cmdRef.current = cmd;
        setOpen(true);
      } else {
        props?.sendCommand(cmd, props?.data?.imei);
        if (cmd === 1) callStartSound();
        else if (cmd === 2) off();
        else if (cmd === 3 || cmd === 4) lock();
      }
    } else licenseModal(navigate, props?.translate);
  };

  const handlePin = () => {
    if (!pin || pin?.length !== 4) {
      message.error(props?.translate("main.enterpin"));
      return;
    }
    setLoading(true);
    props
      ?.request({
        url: `/user/checkpin/${pin}`,
        method: "POST",
        ismessage: true,
      })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setOpen(false);
          setPin("");
          props?.sendCommand(cmdRef?.current, props?.data?.imei);
          if (cmdRef?.current === 1) callStartSound();
          else if (cmdRef?.current === 2) off();
          else if (cmdRef?.current === 3 || cmdRef?.current === 4) lock();
        }
      });
  };
  const onClose = () => setOpen(false);

  return (
    <div className="w-full mt-2 p-2 bg-white dark:bg-black rounded-2xl ">
      <div className="full mb-1 flex justify-between items-center">
        <span className="text-md font-semibold">
          {props?.translate("main.remote")}
        </span>
        <Button
          type="link"
          icon={<HistoryOutlined />}
          className="text-xs"
          loading={loading}
          onClick={onOpenHistory}
        >
          {props?.translate("main.loghistory")}
        </Button>
      </div>
      <div
        className={`grid grid-cols-2 sm:grid-cols-2 ${
          props?.data?.islock ? "md:grid-cols-4" : "md:grid-cols-2"
        } gap-2`}
      >
        {props?.data?.islock === 1 && (
          <Btn
            icon={iLock}
            value="lock"
            className={props?.theme === "dark" ? "active-control" : ""}
            text={props?.translate("main.lock")}
            onClick={() => handleControl(3)}
          />
        )}
        {props?.data?.islock === 1 && (
          <Btn
            icon={iUnlock}
            value="unlock"
            className={props?.theme === "dark" ? "active-control" : ""}
            text={props?.translate("main.unlock")}
            onClick={() => handleControl(4)}
          />
        )}

        <Btn
          icon={iOn}
          value="start"
          loading={props?.loading[`1_${props?.data?.imei}`]}
          text={props?.translate("main.start")}
          onClick={() => handleControl(1)}
        />

        <Btn
          icon={iOff}
          value="off"
          text={props?.translate("main.off")}
          loading={props?.loading[`2_${props?.data?.imei}`]}
          onClick={() => handleControl(2)}
        />
      </div>
      <CustomModal
        title={props?.translate("main.enterpin1")}
        open={open}
        onClose={onClose}
        isShowFooter={false}
        height={200}
      >
        <div className="p-5 flex flex-col justify-between h-full">
          <div className="flex items-center justify-center h-full">
            <Otp otp={pin} setOtp={setPin} size={"large"} open={open} />
          </div>
          <Button
            className="w-full mt-2"
            type="primary"
            onClick={handlePin}
            loading={loading}
          >
            {props?.translate("main.enter")}
          </Button>
        </div>
      </CustomModal>
      <Drawer
        title={props?.translate("main.loghistory")}
        placement="right"
        width={isMobile ? "80%" : 500}
        onClose={onCloseHistory}
        open={openHistory}
      >
        <div className="flex flex-col py-2 px-5 w-full gap-2">
          {history?.length ? (
            history?.map((item, i) => (
              <div className="bg-white dark:bg-card border border-light_border shadow-md rounded-md p-3">
                <div className="flex justify-between text-xs">
                  <div className="flex items-center">
                    <div className="bg-active flex items-center justify-center text-white mr-2 rounded-full w-6 h-6">
                      {i + 1}
                    </div>
                    <span>{item?.insbyname}</span>
                  </div>
                  <span>
                    {dayjs(item?.insymd)?.format("YYYY-MM-DD HH:mm:ss")}
                  </span>
                </div>
                <span className="mt-2 block text-sm">{item?.result}</span>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center py-10">
              <span className="text-border block w-full text-center">
                {`${props?.data?.number} машинд үйлдлийн түүх байхгүй байна`}
              </span>
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default Index;
