const format = /^[0-9\b]+$/;
const count = 8;
const validPassword = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$");
const priceDecimal = /^[0-9]*(\.[0-9]{0,2})?$/;
const priceInteger = /^[0-9]*?$/;
const PATTERN_TTD = new RegExp("^(([А-Яа-яӨҮөү]{2}[0-9]{8})|[0-9]{7})$");

const PRICE_PARSERS = {
  parser: (value) => value.replace(/\$\s?|(,*)/g, ""),
  formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
};

const PHONENO_VALIDATOR = [
  {
    validateTrigger: "onSubmit",
    required: true,
    message: "Утасны дугаар оруулна уу.",
  },
  ({ setFieldsValue }) => ({
    validateTrigger: "onSubmit",
    validator(_, value) {
      if (format.test(value)) {
        if (value.length < count) {
          return Promise.reject(
            new Error("Утасны дугаар 8 оронтой байх ёстой.")
          );
        }
      } else {
        setFieldsValue({ phoneno: value });
        return Promise.reject(new Error("Утасны дугаар дан тоо байх ёстой."));
      }
      return Promise.resolve();
    },
  }),
];
const PASSWORD_VALIDATOR = [{ required: true, message: "Нууц үг оруулна уу." }];

const CREATEPASSWORD_VALIDATOR = [
  {
    validateTrigger: "onSubmit",
    required: true,
    message: "Нууц үг оруулна уу.",
  },
  ({ setFieldsValue }) => ({
    // validateTrigger: "onSubmit",
    validator(_, value) {
      if (!validPassword.test(value) || value.length < count) {
        setFieldsValue({ password: value });
        return Promise.reject(
          new Error("Yсэг, тоо агуулсан 8 буюу түүнээс дээш оронтой байx ёстой")
        );
      }
      return Promise.resolve();
    },
  }),
];

const CONFIRMPASSWORD_VALIDATOR = [
  {
    validateTrigger: "onSubmit",
    required: true,
    message: "Нууц үг оруулна уу.",
  },
  ({ setFieldsValue, getFieldsValue }) => ({
    // validateTrigger: "onSubmit",
    validator(_, value) {
      if (value !== getFieldsValue().password) {
        setFieldsValue({ confirmPassword: value });
        return Promise.reject(new Error("Нууц үг таарахгүй байна"));
      }
      return Promise.resolve();
    },
  }),
];
const EMAIL_VALIDATOR = [
  {
    validateTrigger: "onSubmit",
    required: true,
    type: "email",
    message: "Зөв И-мэйл оруулна уу.",
  },
];

export {
  PHONENO_VALIDATOR,
  PASSWORD_VALIDATOR,
  CREATEPASSWORD_VALIDATOR,
  CONFIRMPASSWORD_VALIDATOR,
  EMAIL_VALIDATOR,
  priceDecimal,
  priceInteger,
  PRICE_PARSERS,
  PATTERN_TTD,
};
