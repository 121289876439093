import { memo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "antd";
import iMongolia from "../../assets/icons/iMongolia.png";
import iUs from "../../assets/icons/iUs.png";

const Index = (props) => {
  const changeLang = () => props?.setLang(props?.lang === "mn" ? "en" : "mn");

  return (
    <Button
      type="ghost"
      className="flex justify-center items-center"
      onClick={changeLang}
    >
      <AnimatePresence key={props?.lang}>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          exit={{ opacity: 1 }}
          src={props?.lang === "mn" ? iMongolia : iUs}
          alt="iLight"
          className="object-contain w-8"
        />
      </AnimatePresence>
    </Button>
  );
};

export default memo(Index, (prev, next) => prev?.lang === next?.lang);
