import { message, Modal } from "antd";
import { useEffect } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;
const mode = "real";

const API_URL = {
  test: "http://10.150.10.28:9090",
  real: "https://api.asaa.mn",
};

const SOCKET_URL = {
  real: "https://socket.asaa.mn",
  test: "https://socket.asaa.mn",
};

const PAYMENT_URL = {
  real: "https://payment.onispos.mn:6970",
  test: "https://payment.onispos.mn:6970",
};

const URLS = {
  main: API_URL[mode],
  socket: SOCKET_URL[mode],
  payment: PAYMENT_URL[mode],
};

const islogged = (props) => {
  if (props?._auth && props?.islogin) return true;
  return false;
};

const isShow = (props) => {
  const { pathname } = window.location;
  if (
    pathname.includes("login") ||
    pathname.includes("register") ||
    pathname.includes("biometrics") ||
    pathname.includes("qrpage")
  )
    return false;
  return islogged(props) ? true : false;
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const saveLoginData = ({ values, data }) => {
  localStorage.setItem("access_token", data?.access_token);
  localStorage.setItem("auth", JSON.stringify(data));
  if (values?.checkbox) localStorage.setItem("name", values?.name);
  else localStorage.removeItem("name");
};

const licenseModal = (navigate, translate) => {
  confirm({
    title: translate("main.licensesms"),
    icon: <ExclamationCircleFilled />,
    content: translate("main.licensedesc"),
    maskClosable: true,
    centered: true,
    cancelText: translate("main.close"),
    okText: translate("main.buy"),
    onOk() {
      navigate("/license");
    },
  });
};

const cancelInvoice = (cancel) => {
  confirm({
    title: "Анхааруулга.",
    icon: <ExclamationCircleFilled />,
    content: "Нэхэмжлэх үүсэгсэн байна. Цуцлах уу ?",
    maskClosable: true,
    centered: true,
    cancelText: "Үгүй",
    okText: "Тийм",
    onOk() {
      cancel && cancel();
    },
  });
};

const postMessageMobile = (data) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }
};

const useMobileEvent = (func) => {
  useEffect(() => {
    const device = localStorage.getItem("deviceType");
    if (device === "ios" || device === "android") {
      if (device === "ios") window.addEventListener("message", func);
      else document.addEventListener("message", func);

      return function cleanupListener() {
        if (device === "ios") window.removeEventListener("message", func);
        else document.removeEventListener("message", func);
      };
    }
  }, [func]);
};

const convertTime = (hour, minute) => {
  let hours = hour ? hour.toString()?.padStart(2, "0") : "00";
  let minutes = minute ? minute.toString()?.padStart(2, "0") : "00";
  return `${hours}:${minutes}`;
};

export {
  URLS,
  islogged,
  isShow,
  getBase64,
  beforeUpload,
  saveLoginData,
  licenseModal,
  cancelInvoice,
  useMobileEvent,
  postMessageMobile,
  convertTime,
};
