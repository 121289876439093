import React from "react";
import { useLocation, Routes, Route } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import { pageMotion } from "../../utils/enums";
import ForgotPassword from "./ForgotPassword";
import Password from "./Password";
import MainInfo from "./MainInfo";
import RegOtp from "./RegOtp";
function Index(props) {
  const location = useLocation();
  return (
    <div className="h-full">
      <AnimatePresence mode="wait" className="h-full">
        <Routes location={location} key={location.pathname} className="h-full">
          <Route path="/forgetpass" element={<ForgotPassword {...props} />} />
          <Route path="/password" element={<Password {...props} />} />
          <Route path="/maininfo" element={<MainInfo {...props} />} />
          <Route path="/regotp" element={<RegOtp {...props} />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default Index;
