import React, { createContext, useReducer } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import * as Context from "../";
import { fetchRequest } from "../fetch";
import { URLS } from "../../utils";
import translation from "../../translate/index.json";

const initialState = {
  islogin: !!JSON.parse(localStorage.getItem("auth")),
  _auth: JSON.parse(localStorage.getItem("auth")),
  notification: false,
  lang: "mn",
  theme: "light",
  color: localStorage.getItem("color") || "#3ae4b4",
  errorMsg: "",
  isOpenError: false,
};

const models = {};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = (props) => {
  const [state, dispatch] = useReducer(Context.appReducer, initialState);

  const addmodel = ({ model }) => {
    models[model] = {
      request: `request_${model}`,
      response: `response_${model}`,
      error: `error_${model}`,
    };
  };
  const navigate = useNavigate();
  const request = async ({
    url,
    model,
    body,
    method = "GET",
    isfile,
    type = "main",
    ismessage = false,
  }) => {
    try {
      const api = URLS[type];
      if (isfile && body) {
        let formData = new FormData();
        Object.keys(body).map((keyname) => {
          formData.append(keyname, body[keyname] ? body[keyname] : null);
          return null;
        });
        body = formData;
      }

      if (model) {
        addmodel({ model: model });
      }

      const res = await fetchRequest({
        url: `${api}/api${url}`,
        method,
        body,
        model: model ? models[model] : null,
        dispatchEvent: dispatch,
        isfile: isfile,
      });
      if (res.status === 401) {
        localStorage.removeItem("auth");
        localStorage.removeItem("access_token");
        setlogin(false);
        navigate("/login");
      }
      if (ismessage && res.success) {
        message.success(res.message);
      } else if (ismessage && !res.success) {
        message.error(res.message);
      }

      return res;
    } catch (error) {
      console.log("%c 🍬 error: ", error);
    }
  };

  const setlogin = (login) => dispatch({ type: "login", response: login });
  const setError = (data) => dispatch({ type: "setError", response: data });
  const setColor = (color) => dispatch({ type: "color", response: color });
  const setLang = (lang) => dispatch({ type: "lang", response: lang });
  const setTheme = (theme) => dispatch({ type: "theme", response: theme });
  const setMobileData = ({ data }) =>
    dispatch({ type: "mobiledata", response: data });
  const setData = ({ data, model }) =>
    dispatch({ type: model, response: data });

  const setNotification = (value) => {
    dispatch({ type: "notification", response: value });
  };

  const translate = (value) => {
    if (translation[value] && translation[value][state?.lang])
      return translation[value][state?.lang];
    return value;
  };

  return (
    <React.Fragment>
      <GlobalContext.Provider
        value={{
          ...state,
          request: request,
          setlogin,
          setNotification,
          setError,
          setData,
          setColor,
          setLang,
          setMobileData,
          setTheme,
          translate,
        }}
      >
        {props.children}
      </GlobalContext.Provider>
    </React.Fragment>
  );
};
