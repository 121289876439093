import { useState, useEffect } from "react";
import { Carousel, message } from "antd";
import { useSocketEvent } from "../../socket";
import PullToRefresh from "react-simple-pull-to-refresh";
import Car from "./car";
const Index = (props) => {
  const [connected, setConnected] = useState({});
  const [loading, setLoading] = useState({});
  const [siminfo, setSimInfo] = useState({});
  const isDemo = props?._auth?.user?.phone === "demo";

  const bACT = (data) => {
    if (data.cmd !== 5 && data?.cmd !== 6) {
      if (data?.result) {
        setLoading({
          ...loading,
          [`${data?.cmd}_${data?.imei}`]: true,
        });
        message.info(data?.result);
      }
    }
  };

  const bRESP = (data) => {
    if (data?.cmd === 5) {
      if (data?.success && data?.data) {
        if (data?.imei) {
          props?.setInfo({
            ...props?.info,
            [data.imei]: { ...data?.data, isactive: data?.isactive },
          });
          if (data?.data?.lattitude && data?.data?.longitude)
            props?.request({
              url: `/system/weather/${data?.data?.lattitude}/${data?.data?.longitude}`,
              model: "weather",
            });
        }
      }
    } else if (data?.cmd === 6) {
      if (data?.message && data?.imei)
        setSimInfo({ ...siminfo, [data.imei]: data?.message });
    } else {
      if (data?.cmd === 8) {
        setLoading({
          ...loading,
          [`1_${data?.imei}`]: false,
        });
      } else {
        setLoading({
          ...loading,
          [`${data?.cmd}_${data?.imei}`]: false,
        });
      }

      if (data?.success) {
        message.success(data?.result);
      } else {
        message.error(data?.result);
      }
    }
  };

  const sendCommand = (cmd, imei, operator) => {
    let body = {
      userid: props?._auth?.user?.id,
      imei: imei,
      cmd: cmd,
    };
    if (operator > 0) body.type = operator;
    else body.type = 0;
    props?.request({
      url: "/action",
      type: "socket",
      method: "POST",
      body: body,
    });
  };

  useEffect(() => {
    if (props?.selectedCar && props?.selectedCar?.imei) {
      if (isDemo) {
        props?.request({
          url: `/system/weather/47.92/106.92`,
          model: "weather",
        });
      }

      /* sendCommand(5, props?.selectedCar?.imei); */
      setTimeout(() => {
        sendCommand(6, props?.selectedCar?.imei, props?.selectedCar?.operator);
      }, 5000);
    }
  }, [props?.selectedCar]);

  useEffect(() => {
    if (props?.data) {
      Promise.all(
        props?.data?.map((item) => {
          props
            ?.request({
              url: `/sock/${item.imei}`,
              method: "GET",
            })
            .then((res) => {
              if (res.success) {
                setConnected((prevConnected) => ({
                  ...prevConnected,
                  [item.imei]: true,
                }));
              }
            });
        })
      )
        .then(() => {})
        .catch((error) => {});
    }
  }, [props.data]);

  const bCONNECTED = (data) => {
    if (data?.imei) {
      /*  sendCommand(5, data.imei); */
      if (props?.selectedCar && props?.selectedCar?.imei === data?.imei) {
        setTimeout(() => {
          sendCommand(6, data.imei, props?.selectedCar?.operator);
        }, 5000);
      }

      setConnected({ ...connected, [data.imei]: true });
    }
  };

  const bDISCONNECTED = (data) => {
    if (data?.imei) {
      setConnected({ ...connected, [data.imei]: false });
    }
  };

  useSocketEvent("B_ACT", bACT);
  useSocketEvent("B_RESP", bRESP);
  useSocketEvent("B_CONNECTED", bCONNECTED);
  useSocketEvent("B_DISCONNECTED", bDISCONNECTED);

  const slideChange = (index) => {
    if (index >= 0 && props?.rescarsinfo?.length && props?.rescarsinfo[index])
      props?.setSelectedCar(props?.rescarsinfo[index]);
  };

  const onRefresh = () => {
    props.request({
      url: `/user/cars`,
      model: "carsinfo",
      method: "POST",
    });
    props.request({
      url: `/timer/list`,
      model: "timerlist",
    });
    props.request({
      url: `/alarm/list`,
      model: "alarmlist",
    });

    props.request({ url: "/user/checklic", model: "licenseinfo" });
    if (props?.selectedCar && props?.selectedCar?.imei) {
      if (connected[props?.selectedCar?.imei]) {
        /* sendCommand(5, props?.selectedCar?.imei); */
        setTimeout(() => {
          sendCommand(
            6,
            props?.selectedCar?.imei,
            props?.selectedCar?.operator
          );
        }, 5000);
      }
    }
  };

  const handleRefresh = async () => {
    await onRefresh();
  };

  return (
    <PullToRefresh onRefresh={handleRefresh} pullingContent={null}>
      <Carousel
        draggable
        swipeToSlide
        initialSlide={0}
        afterChange={slideChange}
      >
        {props?.data?.map((item, i) => (
          <Car
            {...props}
            data={item}
            key={i}
            siminfo={siminfo}
            isDemo={isDemo}
            connected={connected}
            loading={loading}
            info={props?.info}
            sendCommand={sendCommand}
          />
        ))}
      </Carousel>
    </PullToRefresh>
  );
};

export default Index;
