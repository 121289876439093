import { useState, useEffect } from "react";
import { SET_USER_LOCATION } from "../../../data/forms";
import { Form as AntdForm } from "antd";
import { Popover, Button } from "antd";
import { Form } from "../..";
import { CarOutlined } from "@ant-design/icons";
import iLocation from "../../../assets/icons/iLocation.png";
const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = AntdForm.useForm();
  const saveLocation = (values) => {
    setLoading(true);
    props
      ?.request({
        url: `/user/userlocation/edit/${values?.carid}`,
        method: "POST",
        body: values?.userid,
        ismessage: true,
      })
      .then((res) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    form?.setFieldValue("userid", props?.loclist);
  }, [props?.loclist]);

  useEffect(() => {
    form?.setFieldValue("carid", props?.selected?.id);
  }, [props?.selected]);

  return (
    <Popover
      trigger="click"
      content={
        <div className="">
          <Form
            {...props}
            form={form}
            loading={loading}
            handleSave={saveLocation}
            className="mt-2"
            rescarusers={props?.carusers}
            data={SET_USER_LOCATION}
          />
        </div>
      }
      title={props?.translate("main.configlocation")}
    >
      <Button
        // icon={<img src={iLocation} alt="iLocation" className="h-5 icon" />}
        icon={<CarOutlined />}
        className="flex items-center w-full font-semibold mt-2"
      >
        {props?.translate("main.configlocation")}
      </Button>
    </Popover>
  );
};

export default Index;
